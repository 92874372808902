import React, { useEffect, useState } from 'react';
import { observer } from 'mobx-react';
import { Button, Drawer, Form } from 'antd';
import FormComponent from './FormComponent';
import useStore from '../../../../store';
import { componentProps } from '../../../../store/DeviceStore/DeviceInterface';
import moment from 'moment';
import { Notification, validateFields } from '../../../../config/Global';
import { ErrorProps } from '../../../../store/RootStore/RootInterface';
import { CONSTANT } from '../../../../config/Constant';

const EditComponent: React.FC<componentProps> = observer((props: componentProps) => {
	const { visible, close } = props
	const { DEVICE, ROOT, AUTH } = useStore()

	const [form] = Form.useForm()
	const [disabled, setDisabled] = useState<boolean>(true)
	const [saving, setSaving] = useState<boolean>(false)

	const handleChange = () => {
		validateFields(form, setDisabled);
	}

	const handleSubmit = (data: any) => {
		setSaving(true)
		if (data.allow_app_time === CONSTANT.DEVICES.ALLOW_APP_TIME.CHECKED) {
			data.tv_start_time = moment(data.tv_start_time).format('HH:mm')
			data.tv_close_time = moment(data.tv_close_time).format('HH:mm')
			data.app_restart_time = moment(data.app_restart_time).format('HH:mm')
		} else {
			data.allow_app_time === CONSTANT.DEVICES.ALLOW_APP_TIME.UNCHECKED
		}
		if (data.startup_layout === CONSTANT.DEVICES.STARTUP_LAYOUT.CHECKED) {
			data.startup_layout_duration = Number(data.startup_layout_duration)
		}
		data.company_id = AUTH.user?.company_id ? AUTH.user?.company_id : DEVICE.editValues?.company_id
		DEVICE.EditData(data, DEVICE.editValues?.id).then((data: any) => {
			Notification.success({ message: data.data })
			handleClose()
			setDisabled(true)
		}).catch((e: ErrorProps) => {
			if(e?.data?.STATUS){
				ROOT.assignErrorToInput(form, e?.data?.STATUS);
			}
		}).finally(() => setSaving(false))
	}
	const handleClose = () => {
		close()
		form.resetFields()
		setDisabled(true)
	}

	useEffect(() => {
		if (DEVICE.editValues) {
			form.setFieldsValue({
				pair_code: DEVICE.editValues?.pair_code,
				name: DEVICE.editValues?.name,
				description: DEVICE.editValues?.description,
				scaling: DEVICE.editValues?.scaling,
				api_path: DEVICE.editValues?.api_path,
				hardware_type_id: DEVICE.editValues?.hardware_id,
				allow_app_time: DEVICE.editValues?.allow_app_time,
				startup_layout: DEVICE.editValues?.startup_layout,
				oriantation: DEVICE.editValues?.oriantation,
				flipped_orientation: DEVICE.editValues?.flipped_orientation,
			})
			if (DEVICE.editValues?.allow_app_time === CONSTANT.DEVICES.ALLOW_APP_TIME.CHECKED) {
				form.setFieldsValue({
					tv_start_time: moment(DEVICE.editValues?.tv_start_time, "HH:mm"),
					tv_close_time: moment(DEVICE.editValues?.tv_close_time, "HH:mm"),
					app_restart_time: moment(DEVICE.editValues?.app_restart_time, "HH:mm"),
				})
			}
			if (DEVICE.editValues?.startup_layout_duration) {
				form.setFieldsValue({
					startup_layout: CONSTANT.DEVICES.STARTUP_LAYOUT.CHECKED,
					startup_layout_duration: DEVICE.editValues?.startup_layout_duration,
				})
			} else {
				form.setFieldsValue({
					startup_layout: CONSTANT.DEVICES.STARTUP_LAYOUT.UNCHECKED,
				})
			}
			DEVICE.hardware_list = [{ id: DEVICE.editValues?.hardware_id, hardware_name: DEVICE.editValues?.hardware_name }]
		}
	}, [form, DEVICE.editValues])


	return (
		<Drawer
			open={visible}
			onClose={handleClose}
			width={'50%'}
			className='commanDrawer'
			title={`Edit Device`}
			destroyOnClose={true}
			footer={[
				<div key="1">
					<Button className={`${disabled ? 'disableBtn' : 'blackLineBtn'}`}
						disabled={disabled}
						form='addEditDeviceForm' loading={saving} htmlType="submit" type="primary">Save</Button>
					<Button className="orangeBgBtn cancelBtn" onClick={handleClose}>Cancel</Button>
				</div>
			]}
		>
			<FormComponent form={form} handleSubmit={handleSubmit} onChange={handleChange} type='edit' />
		</Drawer>
	)
})

export default EditComponent