import { MinusCircleOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { Col, Form, FormInstance, Row } from "antd";
import { observer } from "mobx-react";
import { InputBox } from "../../../../components/AntdAddons";
import React, { useEffect } from "react";
import { LayoutRequest } from "../../../../requests/PageRequest";
// import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faGripVertical } from "@fortawesome/free-solid-svg-icons";
import MixImageElement from "./MixImageElement";
import MixVideoElement from "./MixVideoElement";
import useStore from "../../../../store";
import Config from "../../../../config/Config";

interface formInterface {
  form: FormInstance;
  name: string;
  label: string;
  onChange: () => void;
  layout_id: any;
  layoutIdFlag?: boolean;
  setShow?: () => void;
}

const ImageVideoLayout: React.FC<formInterface> = observer((props: formInterface) => {
  const { name, onChange, layout_id, form, layoutIdFlag, setShow, label } = props;
  const { LAYOUT } = useStore();

  useEffect(() => {
    if (layout_id && layoutIdFlag) {
      form.setFieldValue(name, "");
      form.setFieldValue("global_timer", null);
      form.setFieldValue("mix_slider", "");
      if (name === "image_video") {
        const fieldValue = [{ type: 1 }];
        form.setFieldsValue({
          ["mix_slider"]: fieldValue
        });
      }
    }
  }, [layout_id]);

  // To create a new array when drag ends 
  // const handleSequenceChange = (result: any) => {
  //   if (result.source?.index >= 0 && result.destination?.index >= 0) {
  //     const getFieldValue = form.getFieldValue(["mix_slider"]);
  //     const prevCategoryListCopy = getFieldValue;
  //     const dragged = prevCategoryListCopy[result.source.index];
  //     prevCategoryListCopy.splice(result.source.index, 1);
  //     prevCategoryListCopy.splice(result.destination.index, 0, dragged);
  //     form.setFieldsValue({ mix_slider: prevCategoryListCopy });
  //     onChange();
  //   }
  // };

  // const deleteItem = (name:number) => {
  //   if (typeof form.getFieldValue(`mix_slider`)?.[name]?.file !== "object") {
  //     const imageFieldId = LAYOUT.editValues.layout_field.find(
  //       (data: any) => data.shortcode === `mix_layout_${Number(name) + 1}_url`
  //     ).id;
  //     const payload = {
  //       file_path: "/storage/media/file/" + form.getFieldValue(`mix_slider`)?.[name]?.file,
  //       layout_field_id: imageFieldId,
  //     };
  //     LAYOUT.RemoveFileApi(LAYOUT.editValues.id, payload);
  //     // toggle();
  //   }
  //   // setFileList([]);
  //   const fieldValue = form.getFieldValue("mix_slider");
  //   fieldValue[`${name}`]["file"] = null;
  //   form.setFieldsValue({
  //     ["mix_slider"]: fieldValue
  //   });
  //   onChange();
  //   return true;
  // };

  return (
    <>
      <Row gutter={Config.gutter} className={"withoutBorder"}>
        <Col
          xs={{ span: 24 }}
          md={{ span: 10 }}
          lg={{ span: 7 }}
        >
          <InputBox.Text
            name={"global_timer"}
            label={`Image Slide Timeout [in Sec.]`}
            placeholder="Enter Image Slide Timeout"
            onChange={onChange}
            required
            rules={LayoutRequest.image_slide_timout}
          ></InputBox.Text>
        </Col>
      </Row>
      {/* <DragDropContext onDragEnd={handleSequenceChange}>
        <Droppable droppableId="sequence">
          {(provided: any) => (
            <div ref={provided.innerRef} {...provided.droppableProps}> */}
      <Form.List name="mix_slider">
        {(fields, { add, remove }) => {
          const myFields = fields?.length
            ? fields
            : [
              {
                name: 0,
                key: 0,
                isListField: true,
                fieldKey: 0,
              },
            ];
          return (
            <>
              {myFields.map(({ key, name, ...restField }) => {
                // <Draggable
                //   key={key}
                //   draggableId={key.toString()}
                //   index={index}
                // >
                //   {(innerProvided: any) => (
                //     <div
                //       ref={innerProvided.innerRef}
                //       {...innerProvided.draggableProps}
                //       key={`sequence-${key}`}
                //     >
                return (
                  <Row gutter={15} className="mixLayout" key={`sequence-${key}`}>
                    {/* <Col
                                  xs={{ span: 24 }}
                                  md={{ span: 2 }}
                                  lg={{ span: 2 }}
                                  className="layoutNameIcon"
                                  style={
                                    form.getFieldValue(["mix_slider"])
                                      ?.length > 1
                                      ? { display: "flex" }
                                      : { display: "none" }
                                  }
                                  // {...innerProvided.dragHandleProps}
                                >
                                  <FontAwesomeIcon
                                    icon={faGripVertical}
                                    size="xl"
                                  />{" "}
                                </Col> */}
                    <Col xs={{ span: 12 }}
                      md={{ span: 5 }}
                      lg={{ span: 5 }}
                      className="layoutName"
                    >
                      <InputBox.Radio
                        label={"Type"}
                        className="formatRadioBtn"
                        name={[name, `type`]}
                        initialValue={1}
                        required
                        {...restField}
                        rules={LayoutRequest.type}
                        onChange={() => {
                          const fieldValue = form.getFieldValue("mix_slider");
                          fieldValue[`${name}`]["file"] = null;
                          form.setFieldsValue({
                            ["mix_slider"]: fieldValue
                          });
                          //Create a copy of the styleProps object
                          const copyStyleProps: any = { ...LAYOUT.styleProps };

                          // Remove the key that we want to delete (e.g., mix_layout_2)
                          copyStyleProps[`mix_layout_${Number(name) + 1}`] = null;

                          // Reassign the updated object to LAYOUT.styleProps
                          LAYOUT.styleProps = copyStyleProps;
                        }}
                        options={{
                          list: [
                            { name: 1, label: "Image" },
                            { name: 2, label: "Video" },
                          ],
                          valueKey: "name",
                          textKey: "label",
                        }}
                      />
                    </Col>

                    <Col
                      xs={{ span: 12 }}
                      md={{ span: 12 }}
                      lg={{ span: 12 }}
                      className="layoutName"
                    >
                      <Form.Item
                        shouldUpdate={(prevValues, curValues) => {
                          return prevValues.mix_slider[name]?.type !== curValues.mix_slider[name]?.type
                        }
                        }
                      >
                        {() => {
                          if (form.getFieldValue(`mix_slider`)?.[name]?.type === 1) {
                            return (
                              <MixImageElement
                                setShow={setShow}
                                onChange={onChange}
                                form={form}
                                name={name}
                                label={"Image"}
                                images={label}
                                layout_id={layout_id}
                                layoutIdFlag={layoutIdFlag}
                                restField={restField}
                              />
                            )
                          }
                          if (form.getFieldValue(`mix_slider`)?.[name]?.type === 2) {
                            return (
                              <MixVideoElement
                                form={form}
                                setShow={setShow}
                                onChange={onChange}
                                name={name}
                                label={"Video"}
                                layout_id={layout_id}
                                layoutIdFlag={layoutIdFlag}
                                restField={restField}
                              />)
                          }
                        }
                        }
                      </Form.Item>
                    </Col>
                    {/* <Col
                    xs={{ span: 24 }}
                    md={{ span: 4 }}
                    lg={{ span: 4 }}
                    className="layoutName"
                  >
                    <InputBox.Number
                      name={[name, `duration`]}
                      label={`Duration (In seconds)`}
                      type="number"
                      placeholder="Enter Duration"
                      {...restField}
                      min={1}
                      onChange={onChange}
                      required
                      rules={LayoutRequest.duration}
                    ></InputBox.Number>
                  </Col> */}
                    <Col
                      xs={{ span: 24 }}
                      md={{ span: 2 }}
                      lg={{ span: 2 }}
                      className="addRemove"
                    >
                      {myFields?.length !== 1 && (
                        <MinusCircleOutlined
                          onClick={() => {
                            remove(name);
                            //Create a copy of the styleProps object
                            const copyStyleProps: any = { ...LAYOUT.styleProps };

                            // Remove the key that we want to delete (e.g., mix_layout_2)
                            delete copyStyleProps[`mix_layout_${Number(name) + 1}`];

                            // Get the remaining keys in sorted order
                            const sortedKeys = Object.keys(copyStyleProps).sort((a, b) => {
                              const numA = parseInt(a.replace('mix_layout_', ''));
                              const numB = parseInt(b.replace('mix_layout_', ''));
                              return numA - numB; // Sort the keys numerically
                            });

                            // Rebuild the object with the new keys (reindexing the keys)
                            const reindexedStyleProps: any = {};
                            sortedKeys.forEach((key, index) => {
                              const newKey = `mix_layout_${index + 1}`; // Reindex starting from 1
                              reindexedStyleProps[newKey] = copyStyleProps[key]; // Assign the value to the new key
                            });

                            // Reassign the updated object to LAYOUT.styleProps
                            LAYOUT.styleProps = reindexedStyleProps;

                            // deleteItem(name)
                            onChange();
                          }}
                        />
                      )}
                      {myFields?.length - 1 === name && (
                        <PlusCircleOutlined
                          onClick={() => {
                            add();
                            onChange();
                          }}
                        />
                      )}
                    </Col>
                    {myFields?.length - 1 !== name && <hr />}
                  </Row>
                )
                // </div>
                // )}
                // </Draggable>
              })}
              {/* {provided.placeholder} */}

            </>
          );
        }}
      </Form.List>
      {/* </div>
          )}
        </Droppable>
      </DragDropContext> */}

    </>
  );
});

export default ImageVideoLayout;
