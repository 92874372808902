import React, { useEffect, useState } from 'react'
import { Button, Col, Row, Form } from 'antd'
import { FormBox, InputBox } from '../../../../components/AntdAddons'
import { EmergencyPopup } from '../../../../requests/PageRequest'
import { Regex } from '../../../../config/Validators'
import { observer } from 'mobx-react'
import useStore from '../../../../store'
import { Notification, validateFields } from '../../../../config/Global'
import { CONSTANT } from '../../../../config/Constant'

const FormComponent = observer(() => {
	const { SETTNG_STORE, AUTH, ROOT } = useStore()
	const [disabled, setDisabled] = useState<boolean>(true)
	const [form] = Form.useForm()
	const [saving, setSaving] = useState<boolean>(false)

	const handleChange = () => {
		var phone = form.getFieldValue('contact_number')
		form.setFieldValue('contact_number', phone && phone.replace(Regex.mobilespace, '$1 $2 $3'))
		validateFields(form, setDisabled);
	}
	const handleSubmit = async (data: any) => {
		setSaving(true)
		const emergency_popup_status = data.emergency_popup_status && data.emergency_popup_status?.[0] === CONSTANT.GLOBAL_POPUP.CHECKED ? 1 : 0;
		
		if (emergency_popup_status !== CONSTANT.GLOBAL_POPUP.CHECKED) {
			data.emergency_popup_text = "";
			data.display_seconds = 0;
			form.setFieldsValue({emergency_popup_text: "", display_seconds:0 })
		}

		const payload = { ...data, emergency_popup_status: emergency_popup_status };

		SETTNG_STORE.updateSetting(payload).then((data: any) => {
			Notification.success({
				message: data.data
			})
			setDisabled(true)
		}).catch((e: any) => {
			ROOT.assignErrorToInput(form, e?.STATUS);
		}).finally(() => setSaving(false))
	}

	useEffect(() => {
		SETTNG_STORE.readSetting()
	}, [AUTH])

	useEffect(() => {


		form.setFieldsValue({
			emergency_popup_text: SETTNG_STORE.readValues?.emergency_popup_text,
			display_seconds: SETTNG_STORE.readValues?.display_seconds,
			rate_condition_line: SETTNG_STORE.readValues?.rate_condition_line,
			text_to_display_when_rates_are_hidden: SETTNG_STORE.readValues?.text_to_display_when_rates_are_hidden,
			contact_number: SETTNG_STORE.readValues?.contact_number,
			email_address: SETTNG_STORE.readValues?.email_address,
			emergency_popup_status: SETTNG_STORE.readValues && SETTNG_STORE.readValues?.emergency_popup_status === CONSTANT.GLOBAL_POPUP.CHECKED ? [1] : []
		})
	}, [SETTNG_STORE.readValues])

	return (
		<div>
			<FormBox title='Emergency Popup Settings' form={form} onFinish={handleSubmit} className='commanDrawer popUpSetting'>
				{AUTH.checkPermission(9, 'add') && (
					<Row gutter={15} className='emergency-popup-checkbox'>
						<Col span={6}>
							<InputBox.Checkbox
								options={{
									list: [{ name: 1, label: 'Enable popup' }],
									valueKey: 'name',
									textKey: 'label'
								}}
								onChange={handleChange} name={'emergency_popup_status'}

							/>
						</Col>
					</Row>
				)}
				<Row gutter={15} className='emergency-popup-input'>
					{AUTH.checkPermission(9, 'add') && (
						<Form.Item
							noStyle
							shouldUpdate={(prevValues, curValues) => {
								return prevValues.emergency_popup_status !== curValues.emergency_popup_status
							}
							}
						>
							{({ getFieldValue }) => {
								const disabledField = getFieldValue(`emergency_popup_status`)?.[0] !== CONSTANT.GLOBAL_POPUP.CHECKED
								return (
									<>
										<Col span={6}>
											<InputBox.Text disabled={disabledField} onChange={handleChange} required={!disabledField} label='Emergency popup text' rules={EmergencyPopup.emergency_popup_text(disabledField)} name={'emergency_popup_text'}></InputBox.Text>
										</Col>
										<Col span={6}>
											<InputBox.Text disabled={disabledField} required={!disabledField} label='Display seconds' onChange={handleChange} rules={EmergencyPopup.display_seconds(disabledField)} name={'display_seconds'}></InputBox.Text>
										</Col>
									</>
								)
							}}
						</Form.Item>
					)}
					{(AUTH.checkPermission(9, 'Rate Setting')) && (<>
						<Col span={6}>
							<InputBox.Text required label='Rate Condition Line' onChange={handleChange} rules={EmergencyPopup.rate} name={'rate_condition_line'}></InputBox.Text>
						</Col>
						<Col span={6}>
							<InputBox.Text required label='Text to display when rates are hidden' onChange={handleChange} rules={EmergencyPopup.text_display} name={'text_to_display_when_rates_are_hidden'}></InputBox.Text>
						</Col>
					</>)}
					<Col span={12} className='settingSaveBtn '>
						<Button type='primary' htmlType='submit' disabled={disabled} loading={saving}
							className={`${disabled ? 'disableBtn' : 'blackLineBtn'}`}>Save</Button>
					</Col>
				</Row>
			</FormBox>
		</div>
	)
})

export default FormComponent