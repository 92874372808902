import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { LayoutRequest } from "../../../../requests/PageRequest";
import { Button, Col, Form, FormInstance, Popover } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPenNib } from "@fortawesome/free-solid-svg-icons";
import { StyleProps } from "../../../../store/LayoutStore/LayoutInterface";
import StyleModalComponent from "../components/StyleModal";
import { planMessage, widgetStyles } from "../../../../config/Global";
import { CONSTANT } from "../../../../config/Constant";
import useStore from "../../../../store";
import CkEditor from "../../../../components/CkEditorComponent";

interface formInterface {
  form: FormInstance;
  name: string;
  label: string;
  onChange: () => void;
  layout_id: any;
  setShow?: (data: any) => void;
  layoutIdFlag?: boolean;
}

const TextElement: React.FC<formInterface> = observer(
  (props: formInterface) => {
    const { form, name, label, onChange, layout_id, setShow, layoutIdFlag } = props;
    const [styleProps, setStyleProps] = useState<StyleProps>({
      styles: [],
      type: "",
    });
    const [applyStyleModal, setApplyStyleModal] = useState<boolean>(false);

    const [dataV, setDataV] = useState();
    const { AUTH } = useStore();

    const openStyleModal = (data: StyleProps) => {
      setStyleProps(data);
      setApplyStyleModal(true);
    };

    const closeStyleModal = () => {
      setApplyStyleModal(false);
    };

    const editorChange = (value?: any) => {
      form.setFieldsValue({
        [name]: value,
      });
    };

    useEffect(() => {
      if (layout_id && layoutIdFlag) {
        form.setFieldValue(name, "");
      }
    }, [layout_id]);

    return (
      <Col>
        <Form.Item
          name={name}
          label={
            <div>
              {label.replace("html", "Text")}
              {AUTH.checkPlan(CONSTANT.INTERACTIVE_CONTENT_SUPPORT) ? (
                <Button
                  type="primary"
                  title="Apply style"
                  onClick={() =>
                    openStyleModal({ styles: widgetStyles.Html(), type: name })
                  }
                  className="ml-5 form-button-icon"
                >
                  <FontAwesomeIcon icon={faPenNib} />
                </Button>
              ) : (
                <Popover title={() => planMessage()}>
                  <Button
                    type="primary"
                    disabled={true}
                    title="Apply style"
                    onClick={() =>
                      openStyleModal({
                        styles: widgetStyles.Html(),
                        type: name,
                      })
                    }
                    className="ml-5 form-button-icon"
                  >
                    <FontAwesomeIcon icon={faPenNib} />
                  </Button>
                </Popover>
              )}
            </div>
          }
          rules={LayoutRequest.popup}
          extra="This widget will display given formatted text as it is"
        >
          <CkEditor
            value={form.getFieldValue([name])}
            onChange={(data: any) => {
              onChange();
              editorChange(data);
            }}
          />
        </Form.Item>
        {applyStyleModal && (
          <Form.Item
            shouldUpdate={(prevValues, curValues) =>
              prevValues.layout_id !== curValues.layout_id
            }
          >
            <StyleModalComponent
              setShow={setShow}
              setDataV={setDataV}
              dataV={dataV}
              visible={applyStyleModal}
              close={closeStyleModal}
              styleProps={styleProps}
            />
          </Form.Item>
        )}
      </Col>
    );
  }
);

export default TextElement;
