export const Regex = {
	mobile: /^\d{4} \d{3} \d{3}$/,
	twoDecimal: /^\d*(\.\d{0,2})?$/,
	onlyWords: /^[a-zA-Z\s]+$/,
	numeric: /^[0-9\b]+$/,
	naturalNumeric: /^[1-9][0-9]*$/,
	email: /^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/,
	password: /(?=.*[0-9])(?=.*[@$!%*#?&])(?=.*[a-z])(?=.*[A-Z])(?=.{6,})/,
	dummyPattern: /^0\.([89]|[8-9][0-9]?)|1\.([1-2]|[1-9][1-9])$/,
	url: /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i,
	mobilespace: /(\d{4})(\d{3})(\d{3})/g,
	scale_pattern: /(0\.[8-9])|1.0|(1\.[1-2])/,	
	marginPadding: /^\d{2} \d{2} \d{2} \d{2}$/,
	DECIMAL2POINT: /^\d+(\.\d{1,2})?$/
};


export const maxName = 100;
export const maxDescription = 500;
