import React, { useEffect, useState } from "react";
import { Button, Col, Form, Popover, Row, Tabs } from "antd";
import type { TabsProps } from "antd";
import { InputBox } from "../../../components/AntdAddons";
import { CustomerRequest, profile } from "../../../requests/PageRequest";
import { observer } from "mobx-react";
import useStore from "../../../store";
import { Notification, passwordTooltip, validateFields } from "../../../config/Global";
import { ErrorProps } from "../../../store/RootStore/RootInterface";
import { LoginRequest } from "../../../requests/AuthRequest";
import { FormInstance } from "antd/es/form/Form";
import { ReactComponent as UserIcon } from "../../../assets/images/icon/user.svg";
import { ReactComponent as PasswordIcon } from "../../../assets/images/icon/lock_final.svg";
import { Regex } from "../../../config/Validators";
import { CONSTANT } from "../../../config/Constant";

const Profile: React.FC<any> = observer(() => {
  const { LAYOUT, ROOT, AUTH, USER } = useStore();
  const { assignErrorToInput } = ROOT;
  const { user } = AUTH;
  const [tooltip, setTooltip] = useState(false);
  const [form] = Form.useForm();
  const profileForm = React.useRef<FormInstance>(null);
  const updatePasswordForm = React.useRef<FormInstance>(null);
  // Save button not enabled when change input. For that reason create ref. but not enbaled yet. Please check.
  const [disabled, setDisabled] = useState<boolean>(true);
  const [saving, setSaving] = useState<boolean>(false);


  useEffect(() => {
    if ([CONSTANT.Company].includes(user?.users_roles_id)) {
      USER.setViewValues(user?.id).then((res: any) => {
        form.setFieldsValue({
          company_name: res?.data?.get_company_detail?.company_name,
          company_email: res?.data?.get_company_detail?.company_email,
          address1: res?.data?.get_company_detail?.address1,
          city_id: res?.data?.get_company_detail?.city_id,
          state_id: res?.data?.get_company_detail?.state_id,
          country_id: res?.data?.get_company_detail?.get_country_name?.id,
          zip: res?.data?.get_company_detail?.zip,
          phone1: res?.data?.get_company_detail?.phone1,
          phone2: res?.data?.get_company_detail?.phone2,
          plan_name: AUTH.subscription?.plan_name
            ? AUTH.subscription.plan_name
            : "",
          price: AUTH.subscription?.amount ? AUTH.subscription?.amount : "",
          time: AUTH.subscription?.interval
            ? AUTH.subscription?.interval_count?.toString() +
            AUTH.subscription?.interval
            : "",
        });

        USER.country_list = [
          USER.viewValues?.get_company_detail.get_country_name,
        ];
        USER.state_list = [USER.viewValues?.get_company_detail.get_state_name];
        USER.city_list = [USER.viewValues?.get_company_detail.get_city_name];
      });
    }
  }, [USER]);

  const onChange = (data: any) => {
    var data = form.getFieldsValue();
    form.setFieldsValue({
      phone1: data.phone1 && data.phone1.replace(Regex.mobilespace, "$1 $2 $3"),
      phone2: data.phone2 && data.phone2.replace(Regex.mobilespace, "$1 $2 $3"),
    });
    validateFields(profileForm?.current, setDisabled);
  };

  const handleSubmit = (formDataValue: any) => {
    setSaving(true);
    const formData = { ...formDataValue, role_id: user?.users_roles_id };
    AUTH.updateProfile(formData)
      .then((data: any) => {
        Notification.success({ message: data.data });
        setDisabled(true);
        setTooltip(false);
      })
      .catch((e: ErrorProps) => {
        assignErrorToInput(profileForm.current, e?.data);
      })
      .finally(() => setSaving(false));
  };

  const onPasswordChange = () => {
    setTooltip(true);
    updatePasswordForm?.current
      ?.validateFields()
      .then(() => {
        setDisabled(false);
        setTooltip(false);
      })
      .catch((e: any) => {
        if (e.errorFields && e.errorFields.length > 0) {
          setDisabled(true);
        }
      });
  };

  const updatePassword = (data: any) => {
    setSaving(true);
    data.old_password = data.old_password ? data.old_password : null;
    AUTH.changePassword(data)
      .then((data: any) => {
        Notification.success({ message: data.message });
        updatePasswordForm?.current?.resetFields();
        setDisabled(true);
      })
      .catch((e: ErrorProps) => {
        assignErrorToInput(updatePasswordForm.current, e?.data.errors);
      })
      .finally(() => setSaving(false));
  };
  // Add ref in form
  const items: TabsProps["items"] = [
    {
      key: "1",
      label: `User Profile`,
      children: (
        <Form
          layout="vertical"
          autoComplete="off"
          ref={profileForm}
          form={form}
          id="publishForm"
          onFinish={handleSubmit}
          onChange={onChange}
          initialValues={LAYOUT.initialValues}
          className="userProfile commanDrawer"
        >
          <Row className="iconRow">
            <div className="formIcon">
              <div className="borderIcon">
                <UserIcon />
              </div>
            </div>
          </Row>
          <Row>
            <Col span={24}>
              <InputBox.Text
                name="name"
                label="Name"
                placeholder="Name"
                rules={profile.name}
                onChange={onChange}
              />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <InputBox.Text
                name="username"
                label="Username"
                onChange={onChange}
                disabled
              />
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <InputBox.Text
                name="email"
                label="Email ID"
                disabled={[CONSTANT.SuperAdmin].includes(user?.users_roles_id)}
                placeholder="Enter Email"
                rules={LoginRequest.email}
              />
            </Col>
          </Row>
          <Row gutter={24}>
            {user?.users_roles_id === CONSTANT.Company ? (
              <>
                <Col span={12}>
                  <InputBox.Text
                    label="Company Name"
                    name={"company_name"}
                    required
                    rules={CustomerRequest.company_name}
                    placeholder="Company Name"
                  ></InputBox.Text>
                </Col>
                <Col span={12}>
                  <InputBox.Text
                    autoComplete="true"
                    label="Email"
                    name={"company_email"}
                    type="email"
                    placeholder="Email"
                    rules={CustomerRequest.email}
                  ></InputBox.Text>
                </Col>
                <Col span={12}>
                  <InputBox.Text
                    required
                    label="Address"
                    name={"address1"}
                    placeholder="Address"
                    rules={CustomerRequest.address}
                  ></InputBox.Text>
                </Col>
                <Col span={12}>
                  <InputBox.Select
                    required
                    onChange={onChange}
                    showSearch
                    onFocus={() => USER?.getCountryList()}
                    rules={CustomerRequest.country}
                    filterOption={(input: any, option: any) =>
                      (option?.children || {})
                        .toLowerCase()
                        .includes(input.toLowerCase())
                    }
                    options={{
                      list: USER?.country_list,
                      valueKey: "id",
                      textKey: "name",
                    }}
                    label="Country"
                    name={"country_id"}
                    placeholder="Please Select Country"
                  ></InputBox.Select>
                </Col>
                <Col span={12}>
                  <InputBox.Text
                    required
                    label="State"
                    rules={CustomerRequest.state}
                    name={"state_id"}
                    onChange={onChange}
                    placeholder="State"
                  ></InputBox.Text>
                </Col>
                <Col span={12}>
                  <InputBox.Text
                    required
                    label="City"
                    rules={CustomerRequest.city}
                    name={"city_id"}
                    onChange={onChange}
                    placeholder="City"
                  ></InputBox.Text>
                </Col>
                <Col span={8}>
                  <InputBox.Text
                    required
                    label="Zip Code"
                    rules={CustomerRequest.Zip}
                    name={"zip"}
                    placeholder="Zip Code"
                  ></InputBox.Text>
                </Col>
                <Col span={8}>
                  <InputBox.Text
                    required
                    label="Phone 1"
                    name={"phone1"}
                    rules={CustomerRequest.mobile}
                    placeholder="Phone 1"
                  ></InputBox.Text>
                </Col>
                <Col span={8}>
                  <InputBox.Text
                    label="Phone 2"
                    required={false}
                    name={"phone2"}
                    rules={CustomerRequest.dummy_mobile}
                    placeholder="Phone 2"
                  ></InputBox.Text>
                </Col>
                <Col span={8}>
                  <InputBox.Text
                    label="Plan Name"
                    required={false}
                    disabled
                    name={"plan_name"}
                    placeholder="Plan Name"
                  ></InputBox.Text>
                </Col>
                <Col span={8}>
                  <InputBox.Text
                    label="Price"
                    required={false}
                    disabled
                    name={"price"}
                    placeholder="Price"
                  ></InputBox.Text>
                </Col>
                <Col span={8}>
                  <InputBox.Text
                    label="Subscription Plan Time"
                    required={false}
                    disabled
                    name={"time"}
                    placeholder="Time"
                  ></InputBox.Text>
                </Col>
              </>
            ) : (
              ""
            )}
          </Row>
          <Row gutter={24}>
            {user?.users_roles_id === CONSTANT.Partner ? (
              <>
                <Col span={12} className="withoutBorder">
                  <InputBox.Number
                    required
                    type="number"
                    label="Maximum Company"
                    name={"max_company"}
                    rules={CustomerRequest.maxCompanies}
                    placeholder="Max Company"
                  ></InputBox.Number>
                </Col>
                <Col span={12} className="withoutBorder">
                  <InputBox.Number
                    required
                    type="number"
                    label="Maximum Devices"
                    name={"max_device"}
                    rules={CustomerRequest.maxDevices}
                    placeholder="Max Devices"
                  ></InputBox.Number>
                </Col>
              </>
            ) : (
              ""
            )}
          </Row>
          <div className="profileBtn">
            <Button
              disabled={disabled}
              form="publishForm"
              loading={saving}
              htmlType="submit"
              type="primary"
              className={`${disabled ? "disableBtn" : "blackLineBtn"}`}
            >
              Save
            </Button>
          </div>
        </Form>
      ),
    },
    {
      key: "2",
      label: `Change Password`,
      children: (
        <Form
          layout="vertical"
          autoComplete="off"
          ref={updatePasswordForm}
          id="changePass"
          onChange={onPasswordChange}
          onFinish={updatePassword}
          initialValues={LAYOUT.initialValues}
        >
          <Row className="iconRow">
            <div className="formIcon">
              <div className="borderIcon">
                <PasswordIcon />
              </div>
            </div>
          </Row>
          {AUTH?.haspassword ? (
            <Row gutter={24}>
              <Col span={24}>
                <InputBox.Password
                  required
                  name="old_password"
                  label="Old Password"
                  placeholder="Enter old password"
                  rules={profile.password}
                />
              </Col>
            </Row>
          ) : null}
          <Row>
            <Col span={24}>
              <Popover
                placement="topRight"
                content={passwordTooltip}
                open={tooltip}
                align={{ offset: [0, -70] }}
              >
                <InputBox.Password
                  name="new_password"
                  required
                  label="New Password"
                  placeholder="Enter new password"
                  rules={profile.password}
                />
              </Popover>
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <InputBox.Password
                name="confirm_password"
                label="Confirm Password"
                required
                placeholder="Enter Confirm Password"
                rules={profile.confirmPassword}
              />
            </Col>
          </Row>
          <div className="profileBtn">
            <Button
              disabled={disabled}
              form="changePass"
              loading={saving}
              htmlType="submit"
              type="primary"
              className={`${disabled ? "disableBtn" : "blackLineBtn"}`}
            >
              Update Password
            </Button>
          </div>
        </Form>
      ),
    },
  ];

  const onChangeTab = () => {
    setTooltip(false);
    setDisabled(false);
    setSaving(false);
  };

  useEffect(() => {
    if (AUTH.user) {
      form.setFieldsValue({
        name: AUTH.user?.name,
        username: AUTH.user?.username,
        email: AUTH.user?.email,
        max_company: AUTH?.user?.max_company,
        max_device: AUTH?.user?.max_device,
      });
    }
  }, [AUTH.user]);

  return (
    <div className="profileLayout ">
      <Tabs
        defaultActiveKey="1"
        moreIcon={false}
        items={items}
        onChange={onChangeTab}
      />
    </div>
  );
});

export default Profile;
