import React, { useEffect, useState } from "react";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { Spin } from "antd";

const CkEditor = ({ onChange, onBlur, value, disabled }: any) => {

  const [editorLoaded, setEditorLoaded] = useState<boolean>(false);

  useEffect(() => {
    setEditorLoaded(true);
  }, []);

  return (
    <div>
      {editorLoaded ? (
        <CKEditor
          editor={ClassicEditor}
          data={value ?? ""}
          disabled={disabled}
          onBlur={onBlur}
          config={{
            toolbar: {
              items: [
                'selectAll', '|',
                'heading', '|',
                'bold', 'italic',
                'numberedList', 'bulletedList', '|',
                'blockQuote', '|',
                'undo', 'redo',
              ],
            },
          }}
          onChange={(event: any, editor: any) => {
            const data = editor.getData();
            onChange(data);
          }}
        />
      ) : (
        <div>
          <Spin />
        </div>
      )}
    </div>
  );
};

export default CkEditor;
