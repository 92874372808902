import React from "react";
import { Checkbox, Col, Form, FormInstance, Popover, Row } from "antd";
import { observer } from "mobx-react";
import { FormBox, InputBox } from "../../../../components/AntdAddons";
import { deviceRequest } from "../../../../requests/PageRequest";
import Config from "../../../../config/Config";
import useStore from "../../../../store";
import { CONSTANT } from "../../../../config/Constant";
import { planMessage } from "../../../../config/Global";
import moment from "moment";
// import { ReactComponent as DownIcon } from '../../../../assets/images/icon/left-arrow-icon.svg';
// import { ReactComponent as DownIcon } from '../../../../assets/images/icon/downIcon.svg';

interface formInterface {
  form: FormInstance;
  handleSubmit: (data: any) => void;
  onChange: () => void;
  type?: any;
}

const FormComponent: React.FC<formInterface> = observer(
  (props: formInterface) => {
    const { DEVICE, AUTH } = useStore();
    const { form, handleSubmit, onChange, type } = props;

    return (
      <FormBox
        id={"addEditDeviceForm"}
        form={form}
        onFinish={handleSubmit}
        onChange={onChange}
        initialValues={DEVICE.initialFormValues}
      >
        <Row gutter={Config.gutter}>
          <Col span={12}>
            <InputBox.Text
              name="pair_code"
              label="Pair Code"
              required
              placeholder="Enter Pair Code"
              rules={deviceRequest.pair_code}
              disabled={type === "edit" ? true : false}
            />
          </Col>
          <Col span={12}>
            <InputBox.Text
              required
              name="name"
              label="Name your device"
              placeholder="Enter your device name"
              rules={deviceRequest.name_your_device}
            />
          </Col>
          <Col span={12}>
            <InputBox.Text
              name="description"
              label="Description"
              placeholder="Enter your device name"
              rules={deviceRequest.desc}
            />
          </Col>
          <Col span={6}>
            <InputBox.Select
              name="hardware_type_id"
              label="Hardware Type"
              required
              allowClear
              // className='selectDown'
              // 					showArrow={false}
              // 					extra={
              // 						<DownIcon/>
              // 					}
              onFocus={() => DEVICE?.gethardware()}
              placeholder="Select Hardware Type"
              options={{
                list: DEVICE?.hardware_list,
                valueKey: "id",
                textKey: 'hardware_name',
                rejectedValues: DEVICE?.hardware_list               // temporary to hide the chromecast
                  ? DEVICE?.hardware_list
                    ?.filter((item: any) => item?.id === 2)
                    ?.map((item: any) => item?.id)
                  : null

              }}
              onChange={onChange}
              rules={deviceRequest.hardware_type}
            />
          </Col>
          {(
            ![CONSTANT.SuperAdmin, CONSTANT.SubAdmin, CONSTANT.Partner].includes(
              AUTH.user.users_roles_id
            )
              ? AUTH.checkPlan(CONSTANT.CUSTOM_SCALING)
              : true
          ) ? (
            <Col span={6}>
              <InputBox.Text
                name="scaling"
                label="Scaling"
                required
                placeholder="Enter Scaling"
                rules={deviceRequest.scaling}
              />
            </Col>
          ) : ( // popup errors
            <Popover title={() => planMessage()}>
              <Col span={6}>
                <InputBox.Text
                  name="scaling"
                  label="Scaling"
                  required
                  disabled={true}
                  placeholder="Enter Scaling"
                  rules={deviceRequest.scaling}
                />
              </Col>
            </Popover>
          )}
          <Col span={24} hidden>
            <InputBox.Text
              name="api_path"
              label="API Path"
              onChange={onChange}
              placeholder="Enter your API path"
              rules={deviceRequest.api_path}
            />
          </Col>
          <Col span={8}>
            <InputBox.Radio
              name="oriantation"
              label="Oriantation"
              required
              type="button"
              onChange={onChange}
              options={{
                list: [
                  { id: 0, name: "landscape", label: "Landscape" },
                  { id: 1, name: "portrait", label: "Portrait" },
                ],
                valueKey: "id",
                textKey: "label",
              }}
            />
          </Col>

          <Col span={6}>
            <Form.Item name="flipped_orientation" valuePropName="checked">
              <Checkbox
                onChange={(e: any) => {
                  const value = e.target.checked ? 1 : 0;
                  form.setFieldValue(
                    "flipped_orientation",
                    value
                  );
                  onChange();
                }}
              >
                Flipped Orientation
              </Checkbox>
            </Form.Item>
          </Col>
          {/* For time inputs App start, End , restart time : start */}
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, curValues) => {
              return prevValues.startup_layout !== curValues.startup_layout
            }
            }
          >
            {() => {
              return (
                <>
                  <Col span={8}>
                    <Form.Item name="allow_app_time" valuePropName="checked">
                      <Checkbox
                        disabled={form.getFieldValue(`startup_layout`) === CONSTANT.DEVICES.ALLOW_APP_TIME.CHECKED}
                        onChange={(e: any) => {
                          const value = e.target.checked ? 1 : 0;
                          form.setFieldsValue({
                            allow_app_time: value,
                          });
                          if (value === CONSTANT.DEVICES.ALLOW_APP_TIME.CHECKED) {
                            form.setFieldsValue({
                              tv_start_time: moment("07:00:00", "HH:mm"),
                              tv_close_time: moment("23:00:00", "HH:mm"),
                              app_restart_time: moment("22:00:00", "HH:mm"),
                            })
                          }
                          onChange();
                        }}
                      >
                        Enable kiosk mode
                      </Checkbox>
                    </Form.Item>
                  </Col>
                  <Form.Item
                    noStyle
                    shouldUpdate={(prevValues, curValues) => {
                      return prevValues.allow_app_time !== curValues.allow_app_time
                    }
                    }
                  >
                    {() => {
                      if (form.getFieldValue(`allow_app_time`) === CONSTANT.DEVICES.ALLOW_APP_TIME.CHECKED) {
                        return (
                          <>
                            <Col span={8}>
                              <InputBox.TimePicker
                                name="tv_start_time"
                                label="App Start Time"
                                required
                                placeholder="Enter App Start Time"
                                rules={deviceRequest.tv_start_time}
                                onChange={onChange}
                              />
                            </Col>
                            <Col span={8}>
                              <InputBox.TimePicker
                                name="tv_close_time"
                                required
                                label="App End Time"
                                placeholder="Enter App End Time"
                                rules={deviceRequest.tv_end_time}
                                onChange={onChange}
                              />
                            </Col>
                            {(
                              ![CONSTANT.SuperAdmin, CONSTANT.SubAdmin, CONSTANT.Partner].includes(
                                AUTH.user.users_roles_id
                              )
                                ? AUTH.checkPlan(CONSTANT.AUTO_RESTART_DEVICES)
                                : true
                            ) ? (
                              <Col span={8}>
                                <InputBox.TimePicker
                                  name="app_restart_time"
                                  label="App Restart Time"
                                  placeholder="Enter App Restart Time"
                                  rules={deviceRequest.tv_restart_time}
                                  onChange={onChange}
                                  extra={<div style={{ width: "100%" }}>Note: Restart functionality is only supported by certain devices that require continuous operation.</div>}
                                />
                              </Col>
                            ) : (
                              <Col span={8}>
                                <Popover title={() => planMessage()}>
                                  <InputBox.TimePicker
                                    name="app_restart_time"
                                    label="App Restart Time"
                                    disabled={true}
                                    placeholder="Enter App Restart Time"
                                    rules={deviceRequest.tv_restart_time}
                                    onChange={onChange}
                                    extra={<div style={{ width: "100%" }}>Note: Restart functionality is only supported by certain devices that require continuous operation.</div>}
                                  />
                                </Popover>
                              </Col>
                            )}
                          </>
                        )
                      }
                    }}
                  </Form.Item>
                </>
              )
            }}
          </Form.Item>

          {/* For time inputs App start, End , restart time : End */}
          {/* For  Startup Layout : start */}
          {
            ([CONSTANT.SuperAdmin, CONSTANT.SubAdmin].includes(
              AUTH.user.users_roles_id
            ) || AUTH.user?.allow_guest === CONSTANT.USERS.ALLOW_GUEST.YES) &&
            <Form.Item
              noStyle
              shouldUpdate={(prevValues, curValues) => {
                return prevValues.allow_app_time !== curValues.allow_app_time
              }
              }
            >
              {() => {
                return (

                  <Col span={8} className="deviceStartupDuration">
                    <Form.Item name="startup_layout" valuePropName="checked" required className="startupCheckBox">
                      <Checkbox
                        disabled={form.getFieldValue(`allow_app_time`) === CONSTANT.DEVICES.ALLOW_APP_TIME.CHECKED}
                        onChange={(e: any) => {
                          const value = e.target.checked ? 1 : 0;
                          form.setFieldsValue({
                            startup_layout: value,
                            startup_layout_duration: null
                          });
                          onChange();
                        }}
                      >
                        Startup Layout Duration [in Sec.]
                      </Checkbox>
                    </Form.Item>

                    <Form.Item
                      noStyle
                      shouldUpdate={(prevValues, curValues) => {
                        return prevValues.startup_layout !== curValues.startup_layout
                      }
                      }
                    >
                      {() => {
                        if (form.getFieldValue(`startup_layout`) === CONSTANT.DEVICES.STARTUP_LAYOUT.CHECKED) {
                          return (
                            <>
                              <InputBox.Text
                                name="startup_layout_duration"
                                // label="Startup Layout Duration [in Sec.]"
                                required
                                placeholder="Enter duration [in Sec.]"
                                rules={deviceRequest.startup_layout_duration}
                                onChange={onChange}
                              />
                            </>
                          )
                        }
                      }}
                    </Form.Item>
                  </Col>)
              }}

            </Form.Item>
          }

          {/* For  Startup Layout : End */}
          {/* <Col span={6}>
					<InputBox.Checkbox
						label
						name='app_uninstall'
						options={{
							list: [{ name: 'app uninstall', label: 'App Uninstall' }],
							valueKey: 'name',
							textKey: 'label'
						}}
					/>
				</Col> */}
        </Row>
      </FormBox>
    );
  }
);

export default FormComponent;
