import React, { useEffect, useState, Dispatch, SetStateAction } from "react";
import { Col, Form, FormInstance, Row, Spin } from "antd";
import { observer } from "mobx-react";
import { FormBox, InputBox } from "../../../../components/AntdAddons";
import { UserRequest, CustomerRequest } from "../../../../requests/PageRequest";
import Config from "../../../../config/Config";
import useStore from "../../../../store";
import { CONSTANT } from "../../../../config/Constant";
import { displayFormats } from "../../../../config/Global";
import moment from "moment";

interface formInterface {
  form: FormInstance;
  handleSubmit: (data: any) => void;
  onChange: (data?: any) => void;
  setDefaultPermission?: (data: any) => Dispatch<SetStateAction<any>>;
  type?: any;
}

const FormComponent: React.FC<formInterface> = observer(
  (props: formInterface) => {
    const { AUTH, USER } = useStore();
    const { form, handleSubmit, onChange, type } = props;
    const [roleList, setRoleList] = useState<boolean>(true);
    const [roleid, setRoleId] = useState<any>(false);
    const [maxCompany, setMaxCompany] = useState();

    useEffect(() => {
      if (USER.viewValues && type === "edit") {
        setRoleId(USER.viewValues?.role?.id);
        form.setFieldsValue({
          name: USER.viewValues?.name,
          username: USER.viewValues?.username,
          email: USER.viewValues?.email,
          role_id: USER.viewValues?.role?.id,
          max_company: USER.viewValues?.max_company,
          max_device: USER.viewValues?.max_device,
        });
        USER.roleList = [USER.viewValues?.role];
        const payload = {
          user_id: USER.viewValues?.id,
        };
        USER.getPermissionsList(payload)
          .then((data: any) => {
            form.setFieldsValue({
              permissions: data.data,
            });
          })
          .catch((e: any) => {
            if (e.errors) {
              form.setFields(e.errors);
            }
          });
      }
    }, [form, USER.viewValues]);

    useEffect(() => {
      if (
        [CONSTANT.Company].includes(USER.viewValues?.users_roles_id) &&
        type === "edit"
      ) {
        setRoleId(USER.viewValues?.users_roles_id);
        form.setFieldsValue({
          company_name: USER.viewValues?.get_company_detail?.company_name,
          company_email: USER.viewValues?.get_company_detail?.company_email,
          address1: USER.viewValues?.get_company_detail?.address1,
          city_id: USER.viewValues?.get_company_detail?.city_id,
          state_id: USER.viewValues?.get_company_detail?.state_id,
          country_id: USER.viewValues?.get_company_detail?.get_country_name?.id,
          zip: USER.viewValues?.get_company_detail?.zip,
          phone1: USER.viewValues?.get_company_detail?.phone1,
          phone2: USER.viewValues?.get_company_detail?.phone2,
          from_date: moment(USER.viewValues?.get_company_detail?.from_date),
          to_date: moment(USER.viewValues?.get_company_detail?.to_date),
          allowed_devices: USER.viewValues?.get_company_detail?.allowed_devices,
          plan_name: USER.viewValues?.plan_id,
          store_type: USER.viewValues?.store_type,
          allow_guest: USER.viewValues?.allow_guest
        });
        // if (USER.viewValues?.store_type === CONSTANT.STORE_TYPES.CompanyS3) {
        //   form.setFieldsValue({
        //     aws_bucket: USER.aws_bucket?.['aws_bucket'],
        //     aws_default_region: USER.aws_bucket?.['aws_default_region'],
        //     aws_secret_access_key: USER.aws_bucket?.['aws_secret_access_key'],
        //     aws_access_key_id: USER.aws_bucket?.['aws_access_key_id'],
        //   })
        // }

        // if (USER.viewValues?.store_type === CONSTANT.STORE_TYPES.LocalPath) {
        //   form.setFieldsValue({
        //     local_path: USER.aws_bucket?.['local_path']
        //   })
        // }
        USER.country_list = [
          USER.viewValues?.get_company_detail?.get_country_name,
        ];
        USER.state_list = [USER.viewValues?.get_company_detail?.get_state_name];
        USER.city_list = [USER.viewValues?.get_company_detail?.get_city_name];
      }
    }, [USER.viewValues]);

    return (
      <FormBox
        id={"addeditform"}
        form={form}
        autoComplete="off"
        onFinish={handleSubmit}
        onChange={onChange}
      >
        <Row gutter={Config.gutter}>
          <Col span={24}>
            <InputBox.Text
              name="name"
              label="Name"
              placeholder="Enter Name"
              rules={UserRequest.name}
            />
          </Col>
          <Col span={12}>
            <InputBox.Text
              name="username"
              label="Username"
              placeholder="Enter Username"
              rules={UserRequest.username}
            />
          </Col>
          <Col span={12}>
            <InputBox.Text
              name="email"
              disabled={[CONSTANT.SuperAdmin].includes(
                USER.viewValues?.users_roles_id
              )}
              label="Email ID"
              placeholder="Enter Email"
              rules={UserRequest.email}
            />
          </Col>

          <Col span={12} className="selectRoleType">
            {
              <>
                <InputBox.Select
                  name="role_id"
                  disabled={[CONSTANT.SuperAdmin].includes(
                    USER.viewValues?.users_roles_id
                  )}
                  label="Role type"
                  showSearch
                  allowClear
                  onFocus={() =>
                    roleList &&
                    USER.getRoleList().then(() => setRoleList(false))
                  }
                  notFoundContent={
                    roleList ? <Spin size="small" /> : "No content found"
                  }
                  onChange={(data: any) => {
                    onChange(data);
                    setRoleId(data);
                  }}
                  placeholder="Select Role"
                  filterOption={(input: any, option: any) =>
                    (option?.children || {})
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={{
                    list: USER.roleList,
                    valueKey: "id",
                    textKey: "role_name",
                    rejectedValues: AUTH.user?.user_role?.user_role_id.length
                      ? USER.roleList?.filter(
                        (_x: any) =>
                          _x.id < AUTH.user?.user_role?.user_role_id
                      )
                      :
                      AUTH.user?.company_id
                        &&
                        AUTH.user?.user_role?.user_role_id === CONSTANT.Company
                        ? [
                          CONSTANT.SuperAdmin,
                          CONSTANT.SubAdmin,
                          CONSTANT.Company,
                          CONSTANT.Partner,
                        ]
                        : [CONSTANT.Partner].includes(
                          AUTH.user?.user_role?.user_role_id
                        )
                          ? [
                            CONSTANT.SuperAdmin,
                            CONSTANT.SubAdmin,
                            CONSTANT.Partner,
                            CONSTANT.Employee,
                            6,
                            8,
                          ]
                          : [CONSTANT.SubAdmin].includes(
                            AUTH.user?.user_role?.user_role_id
                          )
                            ? [
                              CONSTANT.SuperAdmin,
                              CONSTANT.SubAdmin,
                              CONSTANT.Employee,
                            ]
                            : [CONSTANT.SuperAdmin, CONSTANT.Employee],
                    requiredValues: [],
                  }}
                  rules={UserRequest.role}
                />
              </>
            }
          </Col>

          {[CONSTANT.Company].includes(roleid) ? (
            <>
              <Col span={12}>
                <InputBox.Text
                  label="Company Name"
                  name={"company_name"}
                  required
                  rules={CustomerRequest.name}
                  placeholder="Company Name"
                ></InputBox.Text>
              </Col>
              <Col span={12}>
                <InputBox.Text
                  autoComplete="true"
                  label="Email"
                  name={"company_email"}
                  type="email"
                  placeholder="Email"
                  rules={CustomerRequest.email}
                ></InputBox.Text>
              </Col>
              <Col span={12}>
                <InputBox.Text
                  required
                  label="Address"
                  name={"address1"}
                  placeholder="Address"
                  rules={CustomerRequest.address}
                ></InputBox.Text>
              </Col>
              <Col span={12}>
                <InputBox.Select
                  required
                  onChange={onChange}
                  showSearch
                  onFocus={() => USER?.getCountryList()}
                  rules={CustomerRequest.country}
                  filterOption={(input: any, option: any) =>
                    (option?.children || {})
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={{
                    list: USER?.country_list,
                    valueKey: "id",
                    textKey: "name",
                  }}
                  allowClear
                  label="Country"
                  name={"country_id"}
                  placeholder="Please Select Country"
                ></InputBox.Select>
              </Col>
              <Col span={12}>
                <InputBox.Text
                  required
                  label="State"
                  rules={CustomerRequest.state}
                  name={"state_id"}
                  onChange={onChange}
                  placeholder="State"
                ></InputBox.Text>
              </Col>
              <Col span={12}>
                <InputBox.Text
                  required
                  label="City"
                  rules={CustomerRequest.city}
                  name={"city_id"}
                  onChange={onChange}
                  placeholder="City"
                ></InputBox.Text>
              </Col>
              <Col span={8}>
                <InputBox.Text
                  required
                  label="Zip Code"
                  rules={CustomerRequest.Zip}
                  name={"zip"}
                  placeholder="Zip Code"
                ></InputBox.Text>
              </Col>
              <Col span={8}>
                <InputBox.Text
                  required
                  label="Phone 1"
                  name={"phone1"}
                  rules={CustomerRequest.mobile}
                  placeholder="Phone 1"
                ></InputBox.Text>
              </Col>
              <Col span={8}>
                <InputBox.Text
                  label="Phone 2"
                  required={false}
                  name={"phone2"}
                  rules={CustomerRequest.dummy_mobile}
                  placeholder="Phone 2"
                ></InputBox.Text>
              </Col>
              <Col span={8}>
              <InputBox.Radio
                  required
                  label="Allow Guest"
                  initialValue={0}
                  options={{
                    list: [{ id: 1, name: 'Yes' }, { id: 0, name: 'No' }]
                  }}
                  name={"allow_guest"}
              />
              </Col>
              {/* Fields for AWS Bucket : start*/}
              <Col span={8}>
                <InputBox.Select
                  required
                  label="Store Type"
                  onChange={() => {
                    onChange();
                    form.setFieldsValue({
                      aws_bucket: null,
                      aws_default_region: null,
                      aws_secret_access_key: null,
                      aws_access_key_id: null,
                      local_path: null
                    })
                  }}
                  name={"store_type"}
                  showSearch
                  allowClear
                  filterOption={(input: any, option: any) =>
                    (option?.children || {})
                      .toLowerCase()
                      .includes(input.toLowerCase())
                  }
                  options={{
                    list: [
                      { id: 1, name: "Cloudyfy S3" },
                      { id: 2, name: "Company S3" },
                      { id: 3, name: "Local S3" },
                    ],
                    textKey: "name",
                    valueKey: "id",
                  }}
                  rules={CustomerRequest.aws_bucket("Store Type")}
                  placeholder="Choose Store Type"
                ></InputBox.Select>
              </Col>
              <Col span={24}>
                <Form.Item
                  shouldUpdate={(prevValues, curValues) =>
                    prevValues.store_type !== curValues.store_type
                  }
                >
                  {() => {
                    const storeTypeChanged = USER.viewValues?.store_type !== form.getFieldValue("store_type");
                    if ([CONSTANT.STORE_TYPES.CompanyS3].includes(form.getFieldValue("store_type"))) {
                      return (
                        <Row gutter={Config.gutter}>
                          <Col span={12}>
                            <InputBox.Password
                              required={type !== "edit" || storeTypeChanged}
                              autoComplete="new-password"
                              label='AWS Bucket'
                              placeholder='AWS Bucket'
                              onChange={onChange}
                              rules={(type !== "edit" || storeTypeChanged) ? CustomerRequest.aws_bucket("AWS Bucket") : []}
                              name={'aws_bucket'}
                            ></InputBox.Password>
                          </Col>
                          <Col span={12}>
                            <InputBox.Password
                              required={type !== "edit" || storeTypeChanged}
                              label='AWS Default Region'
                              autoComplete="new-password"
                              placeholder='AWS Default Region'
                              onChange={onChange}
                              rules={(type !== "edit" || storeTypeChanged) ? CustomerRequest.aws_bucket("AWS Default Region") : []}
                              name={'aws_default_region'}
                            ></InputBox.Password>
                          </Col>
                          <Col span={12}>
                            <InputBox.Password
                              required={type !== "edit" || storeTypeChanged}
                              label='AWS Secret Access Key'
                              autoComplete="new-password"
                              placeholder='AWS Secret Access Key'
                              onChange={onChange}
                              rules={(type !== "edit" || storeTypeChanged) ? CustomerRequest.aws_bucket("AWS Secret Access Key") : []}
                              name={'aws_secret_access_key'}
                            ></InputBox.Password>
                          </Col>
                          <Col span={12}>
                            <InputBox.Password
                              required={type !== "edit" || storeTypeChanged}
                              label='AWS Access Key Id'
                              autoComplete="new-password"
                              placeholder='AWS Access Key Id'
                              onChange={onChange}
                              rules={(type !== "edit" || storeTypeChanged) ? CustomerRequest.aws_bucket("AWS Access Key Id") : []}
                              name={'aws_access_key_id'}
                            ></InputBox.Password>
                          </Col>
                        </Row>
                      )
                    }
                    if ([CONSTANT.STORE_TYPES.LocalS3].includes(form.getFieldValue("store_type"))) {
                      return (
                        <Row gutter={Config.gutter}>
                          <Col span={12}>
                            <InputBox.Text
                              required={type !== "edit" || storeTypeChanged}
                              label='Host Url'
                              placeholder='Enter Host Url'
                              onChange={onChange}
                              rules={(type !== "edit" || storeTypeChanged) ? CustomerRequest.aws_bucket("Host Url") : []}
                              name={'host_url'}
                            ></InputBox.Text>
                          </Col>
                          <Col span={12}>
                            <InputBox.Password
                              required={type !== "edit" || storeTypeChanged}
                              autoComplete="new-password"
                              label='Bucket'
                              placeholder='Bucket'
                              onChange={onChange}
                              rules={(type !== "edit" || storeTypeChanged) ? CustomerRequest.aws_bucket("Bucket") : []}
                              name={'aws_bucket'}
                            ></InputBox.Password>
                          </Col>
                          <Col span={12}>
                            <InputBox.Password
                              required={type !== "edit" || storeTypeChanged}
                              label='Default Region'
                              autoComplete="new-password"
                              placeholder='Default Region'
                              onChange={onChange}
                              rules={(type !== "edit" || storeTypeChanged) ? CustomerRequest.aws_bucket("Default Region") : []}
                              name={'aws_default_region'}
                            ></InputBox.Password>
                          </Col>
                          <Col span={12}>
                            <InputBox.Password
                              required={type !== "edit" || storeTypeChanged}
                              label='Secret Access Key'
                              autoComplete="new-password"
                              placeholder='Secret Access Key'
                              onChange={onChange}
                              rules={(type !== "edit" || storeTypeChanged) ? CustomerRequest.aws_bucket("Secret Access Key") : []}
                              name={'aws_secret_access_key'}
                            ></InputBox.Password>
                          </Col>
                          <Col span={12}>
                            <InputBox.Password
                              required={type !== "edit" || storeTypeChanged}
                              label='Access Key Id'
                              autoComplete="new-password"
                              placeholder='Access Key Id'
                              onChange={onChange}
                              rules={(type !== "edit" || storeTypeChanged) ? CustomerRequest.aws_bucket("Access Key Id") : []}
                              name={'aws_access_key_id'}
                            ></InputBox.Password>
                          </Col>
                        </Row>
                      )
                    }
                  }}
                </Form.Item>
              </Col>
              {/* Fields for AWS Bucket : end*/}
              {[CONSTANT.Partner].includes(
                AUTH.user?.user_role?.user_role_id
              ) /* || (partner === 5) */ && (
                  <>
                    <Col span={8}>
                      <InputBox.Select
                        required
                        label="Plan Name"
                        name={"plan_name"}
                        showSearch
                        options={{
                          list: [
                            { id: 1, name: "Basic" },
                            { id: 2, name: "Standard" },
                            { id: 3, name: "Premium" },
                          ],
                          textKey: "name",
                          valueKey: "id",
                        }}
                        rules={CustomerRequest.plan}
                        placeholder="Plan Name"
                        onChange={onChange}
                      ></InputBox.Select>
                    </Col>
                    <Col span={8}>
                      <InputBox.DatePicker
                        required
                        name="from_date"
                        label="From Date"
                        placeholder="From Date"
                        rules={CustomerRequest.from_date}
                        format={displayFormats.DATE_MONTH_YEAR}
                        onChange={() => onChange()}
                      />
                    </Col>
                    <Col span={8}>
                      <InputBox.DatePicker
                        required
                        name="to_date"
                        label="To Date"
                        placeholder="To Date"
                        rules={CustomerRequest.to_date}
                        format={displayFormats.DATE_MONTH_YEAR}
                        onChange={onChange}
                      />
                    </Col>
                    <Col span={8}>
                      <InputBox.Number
                        className="allowNumber"
                        label="Allowed Devices"
                        type="number"
                        required
                        name={"allowed_devices"}
                        rules={CustomerRequest.allow_device}
                        placeholder="Allowed Devices"
                      // onChange={onChange}
                      ></InputBox.Number>
                    </Col>
                  </>
                )}
            </>
          ) : [CONSTANT.Partner].includes(roleid) ? (
            <>
              <Col span={12} className="withoutBorder">
                <InputBox.Number
                  required
                  type="number"
                  label="Maximum Company"
                  name={"max_company"}
                  value={maxCompany}
                  rules={CustomerRequest.maxCompanies}
                  placeholder="Max Company"
                  onChange={(e: any) => {
                    onChange();
                    setMaxCompany(e);
                  }}
                ></InputBox.Number>
              </Col>
              <Col span={12} className="withoutBorder">
                <InputBox.Number
                  required
                  type="number"
                  label="Maximum Devices"
                  name={"max_device"}
                  rules={CustomerRequest.maxDevices}
                  placeholder="Max Devices"
                  onChange={onChange}
                ></InputBox.Number>
              </Col>
            </>
          ) : null}
        </Row>
      </FormBox>
    );
  }
);

export default FormComponent;
